import { useShallow } from "zustand/react/shallow"
import { useEffect, useState } from "react"
import { useAccount, useChainId, usePublicClient, useSwitchChain } from "wagmi"
import { stakingStore, useStakingStore } from "./store"

import { NETWORK_AVALANCHE } from "../../services/web3/rainbow"
import { stakingService } from "./service"
import { walletService } from "../../components/WalletSection/service"
import classNames from "classnames"

import { StakedBalance } from "./components/StakedBalance"
import { PrizePool } from "./components/PrizePool"
import { StakingTickets } from "./components/StakingTickets"
import { Staking } from "./components/Staking"
import { RaffleResult } from "./components/RaffleResult"
import { StakingInfo } from "./components/StakingInfo"
import { Rewards } from "./components/Rewards"
import { StackingWithdrawModal } from "./components/StackingModals/StackingWithdrawModal"
import { StackingHistoryModal } from "./components/StackingModals/StackingHistoryModal"

import styles from "./StakingPage.module.scss"
import { userService } from "../../services/user/service"

export const StakingPage = () => {
	const { address } = useAccount()
	const chainId = useChainId()
	const { switchChain } = useSwitchChain()

	const staked = stakingStore(useShallow(({ stakedBalance }) => !!stakedBalance))
	const { isStakingInfoOpen } = useStakingStore()
	const client = usePublicClient()

	const [isModalWithdrawOpen, setIsModalWithdrawOpen] = useState(false)
	const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false)

	const handleShowHistoryModal = () => {
		setIsModalHistoryOpen(true)
	}
	const handleShowWithdrawModal = () => {
		setIsModalWithdrawOpen(true)
	}

	useEffect(() => {
		const hasAccessToken = Boolean(localStorage.getItem("accessToken"))

		if (!hasAccessToken) return

		userService.getUserData()
	}, [])

	useEffect(() => {
		stakingStore.setState({ publicClient: client })
	}, [client])

	useEffect(() => {
		switchChain && switchChain({ chainId: NETWORK_AVALANCHE.id })
	}, [chainId, switchChain])

	useEffect(() => {
		if (address) {
			stakingService.getStakingData(address).then(epoch => {
				stakingService.getUserInformationAndProofs(epoch !== null ? +epoch : 0, address)

				stakingService.getWithdrawalsInfo(address)
			})
		}
	}, [address])

	useEffect(() => {
		if (!address) {
			walletService.openWalletModal()
		}
	}, [address])

	return (
		<>
			{isStakingInfoOpen ? (
				<StakingInfo />
			) : (
				<div className={classNames(styles.pageGrid, !(staked && address) && styles.notStakedGrid)}>
					<Staking />
					<RaffleResult handleShowHistoryModal={handleShowHistoryModal} />
					{staked && address ? (
						<>
							<StakingTickets />
							<PrizePool />
							<StakedBalance handleShowWithdrawModal={handleShowWithdrawModal} />
						</>
					) : (
						<>
							{/*<StakingHelp />*/}
							<Rewards />
						</>
					)}
				</div>
			)}
			<StackingWithdrawModal isModalOpen={isModalWithdrawOpen} setIsModalOpen={setIsModalWithdrawOpen} />
			{isModalHistoryOpen && (
				<StackingHistoryModal isModalOpen={isModalHistoryOpen} setIsModalOpen={setIsModalHistoryOpen} />
			)}
		</>
	)
}
