import {useEffect, useLayoutEffect} from "react";
import {WagmiProvider} from "wagmi";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import {AppRoutes, routesArray} from "./constants/routes";
import publicNavigate from "./services/PublicNavigate";
import {wagmiConfig} from "./services/web3/rainbow";

import "@rainbow-me/rainbowkit/styles.css";
import {PrivateRoute} from "./components/PrivateRoute";
import {createLinkWithSearchParams} from "./utils/createLinkWithSearchParams";
import {getFontClass} from "./utils/getFontClass";
import {useTranslation} from "react-i18next";
import {TechnicalWorks} from "./components/TechnicalWorks";
import {healthService} from "./services/healthService/service";
import {useHealthStore} from "./services/healthService/store";
import {Loader} from "./components/Loader";
import BaseLayout from "./layouts/base-layout/BaseLayout";

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { isTecnicalWorks, isTecnicalWorksLoading } = useHealthStore();
  // const userId = userStore(state => state.user?.id);

  useLayoutEffect(() => {
    publicNavigate.setPublicNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    healthService.checkIsTecnicalWorks();
  }, []);

  return (
    <WagmiProvider config={wagmiConfig} reconnectOnMount={true}>
      <QueryClientProvider client={queryClient}>
        <div className={getFontClass(i18n.language)}>
          <Routes>
            {isTecnicalWorks && !isTecnicalWorksLoading && <Route path="*" element={<TechnicalWorks />} />}

            {isTecnicalWorksLoading && (
              <Route
                path="*"
                element={
                  <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
                    <Loader />
                  </div>
                }
              />
            )}

            <>
              {routesArray.map(({ path, Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    // path === AppRoutes.bridge ||
                    path === AppRoutes.staking || path === AppRoutes.vesting || path === AppRoutes.private ? (
                      <BaseLayout>
                        <Component />
                      </BaseLayout>
                    ) : (
                      <PrivateRoute>
                        <Component />
                      </PrivateRoute>
                    )
                  }
                />
              ))}
              <Route path="*" element={<Navigate to={createLinkWithSearchParams(AppRoutes.login)} />} />
            </>
          </Routes>
        </div>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
