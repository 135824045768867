import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { metaMaskWallet, walletConnectWallet, bybitWallet } from "@rainbow-me/rainbowkit/wallets";
import { APP_CHAIN } from "../../pages/staking/constants";

const projectID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string;

export const TON_TESTNET_RPC = "https://testnet.toncenter.com/api/v2/jsonRPC";
export const RPC = APP_CHAIN.rpcUrls.default.http[0];

export const NETWORK = APP_CHAIN;
export const NETWORK_AVALANCHE = APP_CHAIN;

export const wagmiConfig = getDefaultConfig({
  appName: "Pixelverse",
  projectId: projectID,
  chains: [APP_CHAIN],
  syncConnectedChain: true,
  wallets: [
    {
      groupName: "Recommended",
      wallets: [
        () =>
          metaMaskWallet({
            projectId: projectID,
          }),
        () =>
          bybitWallet({
            projectId: projectID,
          }),
        () =>
          walletConnectWallet({
            projectId: projectID,
          }),
      ],
    },
  ],
});
