import { createPublicClient, http } from 'viem';
import { avalancheFuji, mainnet } from 'viem/chains';
import { isMainnet } from "./web3/contracts/constants";
import { PenaltyType } from "./types";

export const client = createPublicClient({
  transport: http(),
  chain: isMainnet ? mainnet : avalancheFuji,
});

export const APP_CHAIN = isMainnet ? mainnet : avalancheFuji;

export const stakingDecimals = 18;

export const GENESIS_LABELS: Record<PenaltyType, string> = {
	[PenaltyType.GENESIS]: "Genesis",
	[PenaltyType.STANDARD]: "Standard",
	[PenaltyType.NO_FEE]: "No fee",
}
