import ClaimInfo from "./components/ClaimInfo/ClaimInfo";
import "./ClaimPage.scss";

const ClaimPrivatePage = () => {
  return <section className="claim-page">
    <ClaimInfo />
    {/*<ClaimVesting/> /!*https://www.figma.com/design/YgUTIT8xg7QPvW6XFGwHIf/Pixelverse-App-(Copy)-(Copy)?node-id=5836-66663&t=8klrRCvKV7X1DKUa-0*!/*/}
  </section>
}

export default ClaimPrivatePage;
