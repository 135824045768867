import React from "react"
import { ModalWithHeader } from "../../../../Modals/ModalWithHeader"
import { useTranslation } from "react-i18next"
import BetaCodeForm from "./components/BetaCodeForm/BetaCodeForm"
import BetaCodeCongrats from "./components/BetaCodeCongrats/BetaCodeCongrats"
import styles from "./BetaCodeModal.module.scss"
import { useBetaStore } from "../../../../../services/beta/store"
import { useUserStore } from "../../../../../services/user/store"

const BetaCodeModal = () => {
	const { t } = useTranslation()
	const { user } = useUserStore()
	const { isModalOpen, actions, isCodeAccepted } = useBetaStore()

	return (
		<ModalWithHeader
			open={isModalOpen}
			onClose={() => actions.setModalOpen(false)}
			title={t("beta.modal.code.title")}
			modalContentClassName={styles.betaCodeModalContent}
		>
			{isCodeAccepted || user?.isWhitelisted ? <BetaCodeCongrats /> : <BetaCodeForm />}
		</ModalWithHeader>
	)
}

export default BetaCodeModal
