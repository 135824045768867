import { SidebarLogo } from "../../assets/iconComponents/SidebarLogo"
import { CloseIcon } from "../../assets/iconComponents/CloseIcon"
import React, { FC, useEffect } from "react"
import { useIsSidebarStatic } from "../../hooks/useIsSidebarStatic"
import Navigation from "./components/Navigation/Navigation"
import Social from "./components/Social/Social"
import "./Sidebar.scss"
import { LanguageDropdown } from "../LanguageDropdown"
import Beta from "./components/Beta/Beta"
import { useUserStore } from "../../services/user/store"
import BetaSkeleton from "./components/Beta/BetaSkeleton"

type ISidebarProps = {
	toggleSidebar: (value?: boolean) => void
}

export const Sidebar: FC<ISidebarProps> = ({ toggleSidebar }) => {
	const { loading: isUserDataLoading } = useUserStore()
	const isMobileSidebar = useIsSidebarStatic()

	useEffect(() => {
		if (!isMobileSidebar) {
			toggleSidebar(false)
		}
	}, [isMobileSidebar, toggleSidebar])

	return (
		<aside className="sidebar">
			<header className="sidebar__header">
				<div className="sidebar__logo">
					<SidebarLogo />
				</div>
				<CloseIcon className="sidebar__close" onClick={() => toggleSidebar()} />
			</header>

			<Navigation className="sidebar__navigation" toggleSidebar={toggleSidebar} />

			{isUserDataLoading ? <BetaSkeleton /> : <Beta />}

			<footer className="sidebar__footer">
				<LanguageDropdown />
				<Social />
			</footer>
		</aside>
	)
}
