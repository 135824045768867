import { Abi, ContractFunctionParameters, http } from "viem"
import { AxiosError } from "axios"
import { createConfig } from "wagmi"
import { multicall } from "@wagmi/core"
import BigNumber from "bignumber.js"

import { BaseService } from "../../services/BaseService"
import { IStakingStore, stakingStore } from "./store"
import { toBn, toHRNumberFloat } from "../../utils/bigNumber"
import { $api, API_ORIGIN } from "../../services/interceptor"
import { contractReadService } from "./web3/service"

import { getErrorMessage } from "../../utils/getErrorMessage"

import calcRewardPerSecAbi from "./web3/contracts/tenetCalculator/calcRewardPerSecond.json"
import { oneDayInMs } from "../../constants/time"
import { APP_CHAIN, stakingDecimals } from "./constants"
import { CONTRACTS_DATA } from "./web3/contracts"

import {
  CurrentEpochDataType,
  GenesisUnstakeResponse,
  IRawStakedInfo,
  PenaltyType,
  RaffleResultResponse,
  RaffleState,
  StakeDataType,
  StakersResponse,
  WithdrawAvailableDate,
  WithdrawSignature,
} from "./types"

class StakingService extends BaseService<IStakingStore> {
	constructor() {
		super(stakingStore);
	}

	getWithdrawSignature = async (
		address: `0x${string}`,
		penaltyType: PenaltyType,
		stakeIndex: number,
		isForce?: boolean,
	) => {
		try {
			const body = {
				wallet: address,
				penaltyType,
				stakeIndex,
				isForce: !!isForce,
			}
			const { data } = await $api.post<WithdrawSignature>(`staking/generate-withdraw-signature`, body, {baseURL: API_ORIGIN})

			return data
		} catch (error) {
			console.log("Load signature data error: ", error)

			throw error
		}
	}

	genesisUnstake = async (
		address: string,
		penaltyType: PenaltyType,
		stakeIndex: number,
	): Promise<GenesisUnstakeResponse | undefined> => {
		try {
			const { data } = await $api.post<GenesisUnstakeResponse>("staking/unstake", { wallet: address, stakeIndex }, {baseURL: API_ORIGIN})

			const genesisData = this.getState().genesis
			const updatedGenesisData = genesisData.map(el => {
				if (el.penaltyType === penaltyType && el.stakeIndex === stakeIndex) {
					return {
						...el,
						unstaked: true,
						untilWithdrawDate: data.untilWithdrawTime,
					}
				} else {
					return el
				}
			})
			console.log("updatedGenesisData", updatedGenesisData)
			this.setState({ genesis: updatedGenesisData })

			return data
		} catch (error) {
			console.log("Genesis unstake error: ", getErrorMessage(error))
		}
	}

	genesisCancelUnstake = async (address: string, penaltyType: PenaltyType, stakeIndex: number) => {
		try {
			await $api.post<GenesisUnstakeResponse>("staking/cancel-unstake", { wallet: address, stakeIndex }, {baseURL: API_ORIGIN})

			const genesisData = this.getState().genesis
			const updatedGenesisData = genesisData.map(el => {
				if (el.penaltyType === penaltyType && el.stakeIndex === stakeIndex) {
					return {
						...el,
						unstaked: false,
						untilWithdrawDate: undefined,
					}
				} else {
					return el
				}
			})
			console.log("updatedGenesisData", updatedGenesisData)
			this.setState({ genesis: updatedGenesisData })
		} catch (error) {
			console.log("Genesis cancel-unstake error: ", getErrorMessage(error))
		}
	}

	async getStakingData(address: `0x${string}`) {
		const web3ContractReadService = contractReadService(address)
		let newEpoch: CurrentEpochDataType = {
			epochIndex: "-1",
			epoch: {
				epochEndTimestamp: "-1",
				epochStartTimestamp: "-1",
				pointsPer1MShares: "0",
			},
			isValid: false,
		}
		this.setState({ loading: true })
		try {
			newEpoch = await contractReadService(address).getEpoch()
			const stakerStakeCount = await web3ContractReadService.getStakerStakeCount()
			const maxBPS = await web3ContractReadService.getMaxBps()
			const penaltyDays = await web3ContractReadService.getPenaltyDays()
			const tokenBalance = (await web3ContractReadService.getTokenBalance()).formatted.toFixed(2, 1)
			const stakedBalance = (await web3ContractReadService.getStakedBalance()).formatted.toFixed(2, 1)

			stakingStore.setState({
				untilRaffleDate: new Date(+(newEpoch.epoch.epochEndTimestamp ?? 0) * 1000),
				raffles: String(+newEpoch.epochIndex + 1),
				newEpoch,
				stakedBalance,
				stakerStakeCount: stakerStakeCount.formatted,
				penaltyDays: penaltyDays.formatted.toNumber(),
				maxBPS: maxBPS.formatted.toNumber(),
				stakingDecimals: +stakingDecimals,
				tokenBalance,
			})

			const { data } = await $api.get<IRawStakedInfo[]>(`/staking/${address}`, {baseURL: API_ORIGIN})

			let stakingInfo = {
				stakedCoins: 0,
				incomeTicketsPerDay: 0,
				isWithdrawn: false,
				prizeFund: 0,
				totalTicketsAmount: 0,
			}

			const genesisData = data.map(el => {
				const secondsInDay = new BigNumber(oneDayInMs)
				const incomePointsPerDay = new BigNumber(el.incomePointsPerDay * 1000 || 0)
				const date = new Date(el.epochStartDate)
				const timestampMilliseconds = date.getTime()

				stakingInfo = {
					stakedCoins: stakingInfo.stakedCoins + (!el.isWithdrawn ? el.earnedPoints : 0),
					incomeTicketsPerDay: stakingInfo.incomeTicketsPerDay + (!el.isWithdrawn ? el.incomePointsPerDay : 0),
					//TODO remove this flag
					isWithdrawn: stakingInfo.isWithdrawn && el.isWithdrawn,
					prizeFund: stakingInfo.prizeFund + el.prizeFund,
					totalTicketsAmount: stakingInfo.totalTicketsAmount + (!el.isWithdrawn ? el.totalUserBalance : 0),
				}

				return {
					isGenesis: true,
					amount: (el.totalUserBalance || 0).toFixed(2).toString(),
					stakedTimestamp: String(timestampMilliseconds),
					rewardPerSecond: incomePointsPerDay.div(secondsInDay),
					withdrawTimestamp: el.epochEndDate,
					penaltyBP: "0",
					penaltyDays: 0,
					shares: "0",
					unstaked: !!el.untilWithdrawDate,
					reward: new BigNumber("0"),
					id: new Date(el.epochStartDate).getTime(),
					withdrawn: el.isWithdrawn,
					penaltyType: el.penaltyType,
					untilWithdrawDate: el.untilWithdrawDate,
					stakeIndex: el.stakeIndex,
				}
			})

			// const stakingInfo = {
			// 	stakedCoins: !data.isWithdrawn ? data.earnedPoints : 0,
			// 	incomeTicketsPerDay: !data.isWithdrawn ? data.incomePointsPerDay : 0,
			// 	isWithdrawn: data.isWithdrawn,
			// 	prizeFund: data.prizeFund,
			// 	totalTicketsAmount: !data.isWithdrawn ? data.totalUserBalance : 0,
			// }
			const stakedContractCoins = stakingStore.getState().stakedBalance

			stakingStore.setState({
				//TODO
				genesis: genesisData,
				// genesis: !data.isWithdrawn && data.totalUserBalance ? genesisData : undefined,
				stakingInfo,
				stakerStakeCount: stakedContractCoins !== "0.00" ? stakerStakeCount.formatted + 1 : stakerStakeCount.formatted,
			})

			if (newEpoch.isValid && address && toBn(stakerStakeCount.formatted).gt(0)) {
				const newReward = await web3ContractReadService.getRewardByStaker(newEpoch.epochIndex)
				const perDay = await web3ContractReadService.getTicketsPerDay(newEpoch.epochIndex)

				stakingStore.setState({
					totalEntries: newReward.formatted.toNumber() || 0,
					entriesPerDay: perDay.formatted.toFixed(2, 1),
				})
			}
		} catch (error) {
			console.log("Contract error", error)
		} finally {
			this.setState({ loading: false })
		}

		return newEpoch ? +newEpoch.epochIndex - 1 : null
	}

	async getUserInformationAndProofs(epoch: number, address: `0x${string}`) {
		let response: RaffleResultResponse | null = null
		const web3ContractReadService = contractReadService(address)
		try {
			const { data } = await $api.get<RaffleResultResponse>(`/staking/winner/${epoch}/${address}`, {baseURL: API_ORIGIN})
			response = data
			const { proofs, amount, index, wallet } = data
			const state = (
				Array.isArray(address)
					? address.toLowerCase() === (wallet && wallet[0].toLocaleLowerCase())
					: address.toLocaleLowerCase() === wallet
			)
				? RaffleState.won
				: RaffleState.lose

			stakingStore.setState({
				previousRaffleResult: data,
				userProofs: { proofs, amount, index },
				previousRaffleStatus: state,
			})
			const isClaimed = await web3ContractReadService.getIsClaimed(index, epoch)
			stakingStore.setState({ isClaimed: isClaimed.formatted })
		} catch (error) {
			const { response } = error as AxiosError
			if (response?.status === 404) {
				stakingStore.setState({
					previousRaffleStatus: RaffleState.lose, //TODO check for tickets === 0 then status = unavailable
					previousRaffleResult: null,
				})
			} else {
				console.log("stakingService ERROR: ", error)
			}
		}
		return response
	}

	async getUserRaffleInfo(epoch: number, address: `0x${string}`) {
		// let response: RaffleResultResponse | null = null
		const web3ContractReadService = contractReadService(address)
		try {
			const { data } = await $api.get<RaffleResultResponse>(`/staking/winner/${epoch}/${address}`, {baseURL: API_ORIGIN})
			// response = data
			const { proofs, amount, index, id } = data
			const wallet = Array.isArray(data.wallet) ? data.wallet[0] || "" : data.wallet

			const state = wallet.toLowerCase() === address?.toLowerCase() ? RaffleState.won : RaffleState.lose
			const isClaimed = await web3ContractReadService.getIsClaimed(index, epoch)
			return {
				status: state,
				isClaimed,
				amount,
				proofs,
				id,
				wallet,
				index,
			}
		} catch (error) {
			const { response } = error as AxiosError
			if (response?.status === 404) {
				return {
					status: RaffleState.lose,
					id: "",
					wallet: [],
					amount: 0,
					index: -1,
					proofs: [],
				}
			} else {
				console.log("stakingService ERROR: ", error)
			}
		}
		return {
			id: "",
			status: RaffleState.lose,
			wallet: [],
			amount: 0,
			index: -1,
			proofs: [],
		}
	}

	async getUserInformationAndProofsHistory(address: `0x${string}`) {
		const { raffles } = this.getState()
		const web3ContractReadService = contractReadService(address)
		const raffleIndex = 0
		const requests = Array.from({ length: +raffles }).map((_, i) => {
			return this.getUserRaffleInfo(i, address)
		})
		const data = await Promise.allSettled(requests)

		const rafflesRequests = Array.from({ length: +raffles }).map((_, i) => {
			return web3ContractReadService.getEpochByIndex(i)
		})

		const rafflesData = await Promise.allSettled(rafflesRequests)
		const raffleData = await web3ContractReadService.getEpochByIndex(raffleIndex)

		const history = data
			.map((res, i) => {
				if (res.status === "fulfilled" && res.value) {
					const raffle = rafflesData[i].status === "fulfilled" ? rafflesData[i].value : null
					return {
						epochIndex: raffle ? raffle.epochIndex : "-1",
						epoch: {
							epochStartTimestamp: raffle ? raffle.epoch.epochStartTimestamp : 0,
							epochEndTimestamp: raffle ? raffle.epoch.epochEndTimestamp : 0,
							pointsPer1MShares: raffle ? raffle.epoch.pointsPer1MShares : 0,
						},
						isValid: raffle ? raffle.isValid : false,
						...res.value,
					}
				} else {
					return {
						id: "lose",
						wallet: [],
						amount: 0,
						index: +raffleData.epochIndex,
						epochIndex: raffleData.epochIndex,
						proofs: [],
						epoch: {
							epochStartTimestamp: raffleData.epoch.epochStartTimestamp,
							epochEndTimestamp: raffleData.epoch.epochEndTimestamp,
							pointsPer1MShares: raffleData.epoch.pointsPer1MShares,
						},
						isValid: raffleData.isValid,
						status: RaffleState.lose,
					}
				}
			})
			.reverse()
		this.setState({ history })
		return history
	}

	async calcShares(address: `0x${string}`, value: string) {
		const web3ContractReadService = contractReadService(address)
		const { newEpoch, stakingDecimals, setIncome } = this.getState()

		if (stakingDecimals && newEpoch) {
			const shares = await web3ContractReadService.calculateShares(value)

			const newIncome = await web3ContractReadService.calculateIncome(newEpoch.epochIndex, shares.raw as string)

			setIncome(value ? newIncome.formatted || 0 : 0)
		}
	}

	async getWithdrawalsInfo(account: `0x${string}`) {
		const { stakerStakeCount, newEpoch } = this.getState()
		const config = createConfig({
			chains: [APP_CHAIN],
			transports: {
				[APP_CHAIN.id]: http(),
			} as any,
		})

		const isAbleToGetWithdraws = account && newEpoch && stakerStakeCount > 0
		try {
			// const { data } = await $api.get<IRawStakedInfo>(`/staking/${account}`, {baseURL: API_ORIGIN});

			// const stakingInfo = {
			//   stakedCoins: !data.isWithdrawn ? data.earnedPoints : 0,
			//   incomeTicketsPerDay: !data.isWithdrawn ? data.incomePointsPerDay : 0,
			//   isWithdrawn: data.isWithdrawn,
			//   prizeFund: data.prizeFund,
			//   totalTicketsAmount: !data.isWithdrawn ? data.totalUserBalance : 0,
			// };

			// stakingStore.setState({ stakingInfo });

			if (isAbleToGetWithdraws) {
				stakingStore.setState({ gettingWithdraws: true })
				let rewardByStakeCallData: ContractFunctionParameters[] = []
				let rewardPerSecondCallData: ContractFunctionParameters[] = []
				let withDrawalsCallData: ContractFunctionParameters[] = []
				const contractsData: ContractFunctionParameters[] = new Array(+stakerStakeCount).fill(1).map((_, index) => {
					rewardByStakeCallData.push({
						address: CONTRACTS_DATA.calculator.address,
						abi: CONTRACTS_DATA.calculator.abi as Abi,
						functionName: "rewardByStake",
						args: [newEpoch?.epochIndex, account, index],
					})
					rewardPerSecondCallData.push({
						address: CONTRACTS_DATA.calculator.address,
						abi: calcRewardPerSecAbi as Abi,
						functionName: "rewardPerSecond",
						args: [newEpoch?.epochIndex, account, index],
					})
					withDrawalsCallData.push({
						address: CONTRACTS_DATA.staking.address,
						abi: CONTRACTS_DATA.staking.abi as Abi,
						functionName: "withdrawals",
						args: [account, index],
					})

					return {
						address: CONTRACTS_DATA.staking.address,
						abi: CONTRACTS_DATA.staking.abi as Abi,
						functionName: "stakers",
						args: [account, index],
					}
				})

				try {
					const withdrawAvailableDateRes = await $api.get<WithdrawAvailableDate>(`staking/withdraw/${account}/timer`, {baseURL: API_ORIGIN})

					if (withdrawAvailableDateRes?.data?.withdrawAvailableDate) {
						stakingStore.setState({ withdrawAvailableDate: withdrawAvailableDateRes?.data?.withdrawAvailableDate })
					}
				} catch (e) {
					console.log("1. Load withdrawals data error: ", getErrorMessage(e))
				}

				// @ts-ignore
				const multicallResponse = await multicall(config, {
					contracts: contractsData.concat(rewardByStakeCallData, rewardPerSecondCallData, withDrawalsCallData),
				})
				const mainData = multicallResponse.slice(0, stakerStakeCount)

				const rewardByStakeData = multicallResponse.slice(stakerStakeCount, stakerStakeCount * 2)

				const rewardPerSecondData = multicallResponse.slice(stakerStakeCount * 2, stakerStakeCount * 3)

				const withdrawalsData = multicallResponse.slice(stakerStakeCount * 3)
				const newStakers: StakeDataType[] = mainData
					.filter(el => el.status === "success")
					.map((response, index) => {
						const { result } = response as { result: StakersResponse }

						return {
							amount: toHRNumberFloat(toBn(`${result[1]}`), +stakingDecimals).toString(),
							penaltyBP: `${result[4]}`,
							penaltyDays: result[3],
							stakedTimestamp: toBn(result[2] * 1000).toString(),
							shares: toHRNumberFloat(toBn(`${result[5]}`), +stakingDecimals).toString(),
							unstaked: result[0],

							rewardPerSecond: toBn(toHRNumberFloat(toBn(`${rewardPerSecondData[index].result}`), +stakingDecimals)),
							reward: toBn(toHRNumberFloat(toBn(`${rewardByStakeData[index].result}`), +stakingDecimals)),
							id: index,
							withdrawTimestamp: ((withdrawalsData[index].result as [boolean, number])[1] * 1000).toString(),
							withdrawn: (withdrawalsData[index].result as [boolean, number])[0],
						}
					})
					.filter(stake => !stake.withdrawn)

				stakingStore.setState({ stakers: [...newStakers] }) //, ...tempData] })
			}
		} catch (e) {
			console.log("2. Load withdrawals data error: ", e)
		} finally {
			stakingStore.setState({ gettingWithdraws: false })
		}
	}

	setWithdrawTimer = async (address: `0x${string}`) => {
		try {
			const body = {
				walletAddress: address,
			}
			const withdrawAvailableDateRes = await $api.post<WithdrawAvailableDate>(
				`staking/withdraw/${address}/timer/start`,
				body,
        {baseURL: API_ORIGIN}
			)

			if (withdrawAvailableDateRes?.data?.withdrawAvailableDate) {
				stakingStore.setState({ withdrawAvailableDate: withdrawAvailableDateRes?.data?.withdrawAvailableDate })
			}
		} catch (e) {
			console.log("3. Load withdrawals data error: ", e)
		}
	}

	clear = () => {
		this.setState({
			loading: false,
			userProofs: null,
			isClaimed: false,
			tickets: 0,
		})
	}

	setStakingInfoIsOpen = (value: boolean) => {
		stakingStore.setState({ isStakingInfoOpen: value })
	}

	setIsRaffleEnded = (value: boolean) => {
		stakingStore.setState({ isRaffleEnded: value })
	}

	setSelectedGenesisItem = (item: StakeDataType | null) => {
		stakingStore.setState({ selectedGenesisItem: item })
	}

	setGenesisWithdrawLoading = (value: boolean) => {
		stakingStore.setState({ isGenesisWithdrawLoading: value })
	}
	setGenesisStakingLoading = (value: boolean) => {
		stakingStore.setState({ isGenesisStakingLoading: value })
	}
}

export const stakingService = new StakingService()
