import { useTranslation } from "react-i18next";
import { IPet } from "../../../../types";
import styles from "../../../../../profile/components/Avatar/AvatarSelection/AvatarsList.module.scss"
import { SelectedPetInfoButtons } from "../../../SelectedPetInfo/SelectedPetButtons";
import { useEffect, useMemo, useState } from "react";
import { useUserStore } from "../../../../../../services/user/store";
import { usePetsStore } from "../../../../store/petsStore";

type IMobilePetInfoDropdownProps = {
  openProofModal: () => void
}

export const MobilePetInfoDropdown: React.FC<IMobilePetInfoDropdownProps> = ({
  openProofModal,
}) => {
  const { t } = useTranslation()
  const {
    pets,
    loading,
    selectedPet,
  } = usePetsStore();
  const { user } = useUserStore();
  const [currentPet, setCurrentPet] = useState<IPet | null>(null);
  const currentUserPet = pets.find(
    ({ userPet }) => !!userPet?.id && userPet?.id === user?.pet?.id
  ) || null;


  useEffect(() => {
    setCurrentPet(currentUserPet)
  }, [currentUserPet])


  const isBought = useMemo(
    () => !!selectedPet?.userPet,
    [selectedPet]
  );

  const isNotEnoughPointsToBuy = selectedPet ?
    !isBought && (user?.spendablePoints?.amount || 0) < selectedPet.price :
    true;
  const isNotEnoughLevel = selectedPet ?
    !isBought &&
    (user?.levelData.currentLevel?.value || 0) < selectedPet.requiredLevel : true;

  const isPetChoosen = selectedPet?.id === currentPet?.id;
  const petLvl = selectedPet?.userPet?.level;


  const isNextEvolutionBought = pets.some(
    ({ rarity, race }) =>
      race === selectedPet?.race && rarity === selectedPet?.rarity + 1
  )
  const isEvolutionAvailable =
    !isNextEvolutionBought && (selectedPet?.userPet?.level || 1) / 10 >= 1;

  const isAbleToLvlUp = petLvl === 20 && !isEvolutionAvailable;

  if (!selectedPet) {
    return null
  }

  const { id, nameLocalizationKey, name, description, descriptionLocalizationKey } = selectedPet

  return <div
    key={id + "dropdown"}
    className={styles.mobile_avatar_info}
  >
    <h3>{t(nameLocalizationKey || "") || name}</h3>
    <span>{t("pets.edit-bot.info")}</span>
    <p className="custom-scrollbar">{t(descriptionLocalizationKey || "") || description}</p>
    <SelectedPetInfoButtons
      currentPet={currentPet}
      isAbleToLvlUp={isAbleToLvlUp}
      isBought={isBought}
      isNotEnoughLevel={isNotEnoughLevel}
      isNotEnoughPointsToBuy={isNotEnoughPointsToBuy}
      isPetChoosen={isPetChoosen}
      openProofModal={openProofModal}
      setCurrentPet={setCurrentPet}
      loading={loading}
    />
  </div>
}
