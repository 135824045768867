import { ClaimStatus, NftPropsRaw } from "../../types"
import { RarityChip } from "../ClaimedEarly/RarityChip"

import { charactersStore } from "../../store"

import css from "./ClaimedItem.module.scss"
import checkIcon from "../../../../assets/icons/check_cirle_black_bordered.svg"

type IClaimedItemProps = {
	isSelectMode: boolean
	handleSelect: (id: string) => void
	selected: boolean
} & Partial<NftPropsRaw>

export const ClaimedItem: React.FC<IClaimedItemProps> = props => {
	const { url, id, rarity, index, isSelectMode, claimStatus, selected, handleSelect } = props
	const { actions } = charactersStore()

	const isEmpty = !(url && id)

	const handleClick = () => {
		if (isSelectMode) {
			if (claimStatus === ClaimStatus.started && id) {
				handleSelect(id)
			}
		} else {
			if (url && id) {
				actions.selectNft(props)
				actions.setIsNftInfoModalOpen(true)
			} else {
				console.log("NFT Select error")
			}
		}
	}

	return (
		<div className={`${css.wrapper} ${isEmpty ? css.empty : ""}`} onClick={isEmpty ? () => {} : handleClick}>
			{selected && <img className={css.wrapper_check} src={checkIcon} alt="check_icon" />}
			<div
				className={isEmpty ? css.wrapper_image_empty : css.wrapper_image}
				style={url ? { backgroundImage: `url(${url})` } : {}}
			/>
			<div className={isEmpty ? css.wrapper_content_empty : css.wrapper_content}>
				{isEmpty ? null : (
					<>
						<h3>#{index || "??????"}</h3>
						{rarity ? <RarityChip rarity={rarity} /> : null}
					</>
				)}
			</div>
		</div>
	)
}
