import { useState, useEffect, useMemo, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "../../../../../../hooks/useWindowWidth";
import { AvatarCard } from "../../../../../profile/components/Avatar/AvatarSelection/AvatarCard";
import { calculateDropdownElementIndex } from "../../../../../profile/components/Avatar/AvatarSelection/avatar.helper";
import { usePetsStore } from "../../../../store/petsStore";
import styles from "../../../../../profile/components/Avatar/AvatarSelection/AvatarsList.module.scss"
import { IPet } from "../../../../types";
import { Loader } from "../../../../../../components/Loader";
import { useUserStore } from "../../../../../../services/user/store";
import { MobilePetInfoDropdown } from "./MobilePetInfoDropdown";
import { petsService } from "../../../../service/service";

type IPetsListProps = {
  selectedPet: IPet | null;
  openProofModal: () => void
}

export const PetsList: React.FC<IPetsListProps> = ({
  selectedPet,
  openProofModal,
}) => {
  const { t } = useTranslation();
  const [selectedAvatarIndex, setSelectedAvatarIndex] = useState<number>(-1)
  const windowWidth = useWindowWidth()
  const {
    pets,
    loading,
  } = usePetsStore();
  const [currentPet, setCurrentPet] = useState<IPet | undefined>();
  const availablePets = pets.filter((pet) => !!pet.userPet);
  const user = useUserStore();
  const [sortedPets, setSortedPets] = useState<IPet[]>([]);
  const currentUserPet = pets.find(
    ({ userPet }) => !!userPet?.id && userPet?.id === user?.user?.pet?.id
  );


  // const { levelUpError } = usePetsStore();
  useEffect(() => {
    setCurrentPet(currentUserPet)
  }, [currentUserPet])

  useEffect(() => {
    petsService.setSelectedPet(availablePets[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedPet?.id) {
      setSelectedAvatarIndex(prev => {
        const newIndex = sortedPets.findIndex(pet => pet.id === selectedPet?.id)
        return newIndex
      }
      )
    }
  }, [selectedPet?.id, sortedPets])


  useEffect(() => {
    if (currentUserPet) {
      setSortedPets([
        currentUserPet,
        ...pets.filter((pet) => pet.id !== currentUserPet.id),
      ]);
    } else {
      setSortedPets([...pets]);
    }
  }, [pets, currentUserPet]);

  const dropdownIndex = useMemo(() => {
    return calculateDropdownElementIndex(selectedAvatarIndex, windowWidth, sortedPets.length)
  }, [selectedAvatarIndex, sortedPets.length, windowWidth])


  const isBought = useMemo(
    () => !!selectedPet?.userPet,
    [selectedPet]
  );
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className="mb-4">{
          `${availablePets.length} / ${pets.length} ${t("pets.edit-bot.avalible")}`
        }</div>
      </div>

      <div className={`${styles.list_placeholder}`}>

        {loading ? <Loader />
          : sortedPets.reduce<(ReactElement)[]>(
            (p, item, index) => {

              const { id, userPet } = item
              const isActivePet = selectedPet?.id === id;
              const isCurrent = currentPet?.id === id;
              const isNotEnoughLevel = selectedPet ?
                !isBought &&
                (user?.user?.levelData.currentLevel?.value || 0) < selectedPet.requiredLevel : true;

              p.push(
                <AvatarCard
                  key={item.id}
                  isPet
                  onClick={() => {
                    petsService.setSelectedPet(item);
                  }}
                  userAvatar={userPet ? { id: userPet.id } : undefined}
                  isCurrent={isCurrent}
                  isSelected={isActivePet}
                  isEnoughLevel={isNotEnoughLevel}
                  requiredLevel={item.requiredLevel}
                  isBought={isBought}
                  level={item.userPet?.level}
                  maxLevel={20}
                  avatar={item}
                />
              )
              if (dropdownIndex === index) {
                p.push(selectedPet ? <MobilePetInfoDropdown
                  key={id + "dropdown"}
                  openProofModal={openProofModal}
                /> : <></>)
              }
              return p;
            }, [])
        }
      </div >
    </div>
  );
};
